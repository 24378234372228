<template>
    <div class="client-claims">
        <div class="client-claims__title-container">
            <Title>
                Claims
            </Title>

            <Button
                theme="transparent"
                :onClick="onAddClick"
            >
                Add claim
            </Button>
        </div>

        <DataTable
            :headers="headers"
            :items="client.claims"
        >
            <template v-slot:actions="{ item }">
                <div class="client-claims__table-actions">
                    <Icon
                        class="client-claims__remove-icon"
                        name="delete"
                        @click="onRemoveClick(item)"
                    />
                </div>
            </template>
        </DataTable>

        <ModalAddClaim
            v-if="modals.addClaim"
            :onClose="() => toggleModal('addClaim')"
        />

        <ConfirmModal
            v-if="modals.removeClaim"
            :text="`Please confirm that you want to remove ${claimToRemove.value}`"
            :onYes="onRemoveConfirm"
            :onClose="() => toggleModal('removeClaim')"
        />
    </div>
</template>

<script src="./ClientClaims.js"></script>

<style
    src="./ClientClaims.scss"
    lang="scss"
    scoped
></style>
