import {
    ref,
    reactive
} from 'vue';
import {
    Title,
    Button,
    DataTable,
    Icon,
    ConfirmModal
} from 'account-ui-components';
import ModalAddScope from '@/components/modals/ModalAddScope/ModalAddScope.vue';

export default {
    name: 'ClientScopes',

    props: {
        client: {
            type: Object,
            default: null
        },
        onRemove: {
            type: Function,
            required: true
        }
    },

    components: {
        Title,
        Button,
        DataTable,
        Icon,
        ConfirmModal,
        ModalAddScope
    },

    setup(props) {
        const headers = [
            {
                value: 'scope',
                text: 'Scope',
                sortable: true
            },
            {
                value: 'actions',
                text: 'Actions',
                width: '90px'
            },
            {
                value: 'add',
                text: '',
                width: '50px'
            }
        ];
        const scopeToRemove = ref(null);
        const modals = reactive({
            addScope: false,
            removeScope: false
        });

        const toggleModal = (modal) => {
            modals[modal] = !modals[modal];
        };

        const onAddClick = () => {
            toggleModal('addScope');
        };

        const onRemoveClick = (scope) => {
            scopeToRemove.value = scope;

            toggleModal('removeScope');
        };

        const onRemoveConfirm = () => {
            toggleModal('removeScope');

            props.onRemove(scopeToRemove.value);
        };

        return {
            headers,
            scopeToRemove,
            modals,
            toggleModal,
            onAddClick,
            onRemoveClick,
            onRemoveConfirm
        };
    }
};
