<template>
    <div class="client-redirects">
        <div class="client-redirects__title-container">
            <Title>
                Redirects
            </Title>

            <Button
                theme="transparent"
                :onClick="onAddClick"
            >
                Add redirect
            </Button>
        </div>

        <DataTable
            :headers="headers"
            :items="client.redirects"
        >
            <template v-slot:actions="{ item }">
                <div class="client-redirects__table-actions">
                    <Icon
                        class="client-redirects__remove-icon"
                        name="delete"
                        @click="onRemoveClick(item)"
                    />
                </div>
            </template>
        </DataTable>

        <ModalAddRedirect
            v-if="modals.addRedirect"
            :onClose="() => toggleModal('addRedirect')"
        />

        <ConfirmModal
            v-if="modals.removeRedirect"
            :text="`Please confirm that you want to remove ${redirectToRemove.uri}`"
            :onYes="onRemoveConfirm"
            :onClose="() => toggleModal('removeRedirect')"
        />
    </div>
</template>

<script src="./ClientRedirects.js"></script>

<style
    src="./ClientRedirects.scss"
    lang="scss"
    scoped
></style>
