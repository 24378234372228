<template>
    <div class="modal-content">
        <slot />
    </div>
</template>

<script src="./ModalContent.js"></script>

<style
    src="./ModalContent.scss"
    lang="scss"
></style>
