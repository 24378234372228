<template>
    <Modal
        :onClose="onClose"
    >
        <ModalContent>
            <h3 class="modal-content__heading">
                Add Scope
            </h3>

            <div class="modal-content__input-container">
                <Select
                    name="resourceId"
                    label="Api Resource"
                    :value="resourceId"
                    :required="true"
                    :disabled="!resources"
                    :onChange="onResourceIdChange"
                >
                    <option
                        v-for="({ id, name }, i) in resources"
                        :key="i"
                        :value="id"
                    >
                        {{ name }}
                    </option>
                </Select>
            </div>

            <div class="modal-content__input-container">
                <Select
                    name="scope"
                    label="Api Scope"
                    :value="scope"
                    :required="true"
                    :disabled="!scopes"
                    :onChange="onScopeChange"
                >
                    <option
                        v-for="({ name }, i) in scopes"
                        :key="i"
                        :value="name"
                    >
                        {{ name }}
                    </option>
                </Select>
            </div>

            <div class="modal-content__buttons-container">
                <div class="modal-content__button-holder">
                    <Button
                        theme="transparent"
                        :fluid="true"
                        :onClick="onClose"
                    >
                        Cancel
                    </Button>
                </div>
                <div class="modal-content__button-holder">
                    <Button
                        :disabled="!scope"
                        :fluid="true"
                        :onClick="onAddClick"
                    >
                        Add
                    </Button>
                </div>
            </div>
        </ModalContent>
    </Modal>
</template>

<script src="./ModalAddScope.js"></script>
