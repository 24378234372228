import {
    computed,
    reactive
} from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { Modal, Input, Button } from 'account-ui-components';
import ModalContent from '../ModalContent/ModalContent.vue';

export default {
    name: 'ModalAddClaim',

    components: {
        Modal,
        ModalContent,
        Input,
        Button
    },

    props: {
        onClose: {
            type: Function,
            required: true
        }
    },

    setup(props) {
        const store = useStore();
        const route = useRoute();
        const clientId = computed(() => route.params.id);
        const claim = reactive({
            type: null,
            value: null
        });

        const onChange = ({ target: { name, value } }) => {
            claim[name] = value;
        };

        const onAddClick = async () => {
            await store.dispatch('addClientClaim', {
                clientId: clientId.value,
                claim: {
                    type: claim.type.trim(),
                    value: claim.value.trim()
                }
            });

            props.onClose();
        };

        return {
            claim,
            onChange,
            onAddClick
        };
    }
};
