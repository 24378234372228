<template>
    <div class="client-list">
        <template v-if="loading.page">
            <div class="client-list__preloader-container">
                <Preloader />
            </div>
        </template>

        <template v-else>
            <div class="client-list__title-container">
                <Title>
                    Clients
                </Title>

                <Button
                    theme="transparent"
                    :onClick="toggleCreateClientModal"
                >
                    Create new client
                </Button>
            </div>

            <DataTable
                :headers="headers"
                :items="clients.items"
                :filterable="true"
                filterPlaceholder="Filter"
                :onRowClick="onClientClick"
            />

            <div class="client-list__infinite-scroll-container">
                <VueEternalLoading
                    :load="loadMoreClients"
                >
                    <template #loading>
                        <Preloader />
                    </template>
                    <template #no-more>
                        <div></div>
                    </template>
                    <template #no-results>
                        <div></div>
                    </template>
                </VueEternalLoading>
            </div>

            <ModalCreateClient
                v-if="showCreateClientModal"
                :onClose="toggleCreateClientModal"
            />
        </template>
    </div>
</template>

<script src="./ClientList.js"></script>

<style
    src="./ClientList.scss"
    lang="scss"
    scoped
></style>
