<template>
    <Modal
        :onClose="onClose"
    >
        <div class="modal-create-client">
            <div class="modal-create-client__title-container">
                <Title>
                    Create client
                </Title>
            </div>

            <template v-if="loading.modal">
                <div class="modal-create-client__preloader-container">
                    <Preloader />
                </div>
            </template>

            <template v-else>
                <div class="modal-create-client__input-container">
                    <Input
                        label="Name"
                        name="clientId"
                        :value="client.clientId"
                        :error="errors.clientId"
                        required
                        :onChange="onChange"
                        :onBlur="onBlur"
                    />
                </div>

                <div class="modal-create-client__input-container">
                    <Input
                        label="Description"
                        name="description"
                        :value="client.description"
                        required
                        :onChange="onChange"
                        :onBlur="onBlur"
                    />
                </div>

                <div class="modal-create-client__input-container">
                    <Dropdown
                        label="Type"
                        name="type"
                        :items="types"
                        :value="client.type"
                        required
                        :disabled="!types"
                        :onChange="onDropdownChange"
                    />
                </div>

                <div class="modal-create-client__input-container">
                    <Dropdown
                        label="Classification"
                        name="classification"
                        :items="classifications"
                        :value="client.classification"
                        :disabled="!classifications"
                        :onChange="onDropdownChange"
                    />
                </div>

                <div class="modal-create-client__input-container">
                    <Checkbox
                        label="Enable Offline access"
                        name="offlineAccess"
                        value="offlineAccess"
                        :checked="client.offlineAccess"
                        :onChange="onChange"
                    />
                </div>

                <div class="modal-create-client__button-container">
                    <Button
                        :disabled="!clientValid"
                        :loading="loading.button"
                        :onClick="createClient"
                    >
                        Create
                    </Button>
                </div>
            </template>
        </div>
    </Modal>
</template>

<script src="./ModalCreateClient.js"></script>

<style
    src="./ModalCreateClient.scss"
    lang="scss"
    scoped
></style>
