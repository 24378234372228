import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '@webcomponents/webcomponentsjs/webcomponents-bundle';
import { createApp } from 'vue';
import '3shape-global-nav';
import router from '@/router';
import store from '@/store';
import 'account-ui-components/dist/ui-components.css';
import './style/base.scss';
import App from '@/components/App/App.vue';

const app = createApp(App);

app.use(router);
app.use(store);

app.mount('#app');
