import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import ClientScopes from '@/components/ClientScopes/ClientScopes.vue';

export default {
    name: 'ClientScopesContainer',

    components: {
        ClientScopes
    },

    setup() {
        const store = useStore();
        const route = useRoute();
        const clientId = computed(() => route.params.id);
        const client = computed(() => store.getters.getClientById(clientId.value));

        const onRemove = ({ scope }) => {
            store.dispatch('removeClientScope', {
                clientId: clientId.value,
                scope
            });
        };

        return {
            client,
            onRemove
        };
    }
};
