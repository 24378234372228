import {
    ref,
    computed,
    reactive
} from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import {
    Modal,
    Title,
    Input,
    Button
} from 'account-ui-components';

export default {
    name: 'ModalDuplicateClient',

    components: {
        Modal,
        Title,
        Input,
        Button
    },

    props: {
        onDuplicate: {
            type: Function,
            required: true
        },
        onClose: {
            type: Function,
            required: true
        }
    },

    setup(props) {
        const store = useStore();
        const route = useRoute();
        const loading = reactive({
            button: false
        });
        const errors = reactive({
            name: ''
        });
        const newClientName = ref('');
        const clientId = computed(() => route.params.id);

        const onInputChange = (event) => {
            const { value } = event.target;

            if (errors.name) {
                errors.name = '';
            }

            newClientName.value = value;
        };

        const duplicateClient = async () => {
            loading.button = true;

            try {
                const newClientId = await store.dispatch('duplicateClient', {
                    clientId: clientId.value,
                    newClientName: newClientName.value
                });

                props.onDuplicate(newClientId);
            } catch (error) {
                if (
                    error.response.status === 400
                    && error.response.data === 'ClientId is already exists'
                ) {
                    errors.name = 'This name is already exists';
                }

                loading.button = false;
            }
        };

        return {
            loading,
            errors,
            newClientName,
            onInputChange,
            duplicateClient
        };
    }
};
