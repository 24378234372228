import axios from 'axios';

export default {
    makeRequest({
        method = 'GET',
        url,
        data,
        headers
    }) {
        const options = {
            method,
            url,
            data
        };

        options.headers = { 'X-CSRF': '1', ...headers };

        return axios(options);
    },

    getUser() {
        return this.makeRequest({ url: '/backend/bff/user' });
    }
};
