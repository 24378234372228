import { ref } from 'vue';
import { VueEternalLoading } from '@ts-pro/vue-eternal-loading';
import {
    Preloader,
    Title,
    Button,
    DataTable
} from 'account-ui-components';
import { ModalCreateClient } from '@/components/modals';

export default {
    name: 'ClientList',

    props: {
        loading: {
            type: Object,
            required: true
        },
        clients: {
            type: Object,
            default: null
        },
        loadMoreClients: {
            type: Function,
            required: true
        },
        onClientClick: {
            type: Function,
            required: true
        }
    },

    components: {
        VueEternalLoading,
        Preloader,
        Title,
        Button,
        DataTable,
        ModalCreateClient
    },

    setup() {
        const headers = [
            {
                value: 'id', text: 'Id', sortable: true, filterable: true, width: '75px'
            },
            {
                value: 'clientId', text: 'Name', sortable: true, filterable: true
            },
            {
                value: 'classification', text: 'Classification', sortable: true, filterable: true
            },
            {
                value: 'allowOfflineAccess', text: 'Offline Access', sortable: true, filterable: true, width: '155px'
            },
            {
                text: '', value: 'createClient', width: '50px'
            }
        ];
        const showCreateClientModal = ref(false);

        const toggleCreateClientModal = () => {
            showCreateClientModal.value = !showCreateClientModal.value;
        };

        return {
            headers,
            showCreateClientModal,
            toggleCreateClientModal
        };
    }
};
