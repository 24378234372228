/* eslint-disable */
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import {
    Modal,
    Title
} from 'account-ui-components';
import vueFilePond from 'vue-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size';

const FilePond = vueFilePond(
    FilePondPluginImagePreview,
    FilePondPluginFileValidateType,
    FilePondPluginImageValidateSize
);

export default {
    name: 'ModalLogoUpload',

    props: {
        onClose: {
            type: Function,
            required: true
        }
    },

    components: {
        Modal,
        Title,
        FilePond
    },

    setup() {
        const store = useStore();
        const route = useRoute();
        const clientId = computed(() => route.params.id);

        const serverOptions = {
            process: async (fieldName, file, metadata, load, error, progress, abort) => {
                const reader = new FileReader();
                reader.readAsArrayBuffer(file);
                reader.onloadend = async () => {
                    const blob = new Blob([ reader.result ], { type: 'image/jpeg' })
                    await store.dispatch('uploadClientLogo', {
                        clientId: clientId.value,
                        blob
                    });
                    load();
                }

                return {
                    abort: () => {
                        abort();
                    }
                };
            }
        };

        return {
            serverOptions
        };
    }
};
