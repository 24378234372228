<template>
    <div class="header">
        <Logo />

        <g-navigation
            ref="navigation"
            :env="env !== 'development' ? env : 'test'"
            :username="`${username} `"
            showAdditionalApps
        ></g-navigation>
    </div>
</template>

<script src="./Header.js"></script>

<style
    src="./Header.scss"
    lang="scss"
    scoped
></style>
