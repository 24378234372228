import {
    ref,
    reactive
} from 'vue';
import {
    Title,
    Button,
    DataTable,
    Icon,
    ConfirmModal
} from 'account-ui-components';
import ModalAddRedirect from '@/components/modals/ModalAddRedirect/ModalAddRedirect.vue';

export default {
    name: 'ClientRedirects',

    props: {
        client: {
            type: Object,
            default: null
        },
        onRemove: {
            type: Function,
            required: true
        }
    },

    components: {
        Title,
        Button,
        DataTable,
        Icon,
        ConfirmModal,
        ModalAddRedirect
    },

    setup(props) {
        const headers = [
            {
                value: 'uri',
                text: 'RedirectUrl',
                sortable: true
            },
            {
                value: 'actions',
                text: 'Actions',
                width: '90px'
            },
            {
                value: 'add',
                text: '',
                width: '50px'
            }
        ];
        const redirectToRemove = ref(null);
        const modals = reactive({
            addRedirect: false,
            removeRedirect: false
        });

        const toggleModal = (modal) => {
            modals[modal] = !modals[modal];
        };

        const onAddClick = () => {
            toggleModal('addRedirect');
        };

        const onRemoveClick = (redirect) => {
            redirectToRemove.value = redirect;

            toggleModal('removeRedirect');
        };

        const onRemoveConfirm = () => {
            toggleModal('removeRedirect');

            props.onRemove(redirectToRemove.value);
        };

        return {
            headers,
            redirectToRemove,
            modals,
            toggleModal,
            onAddClick,
            onRemoveClick,
            onRemoveConfirm
        };
    }
};
