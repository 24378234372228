<template>
    <ClientSettings
        :loading="loading"
        :client="client"
        :grants="grants"
        :onSave="onSave"
    />
</template>

<script src="./ClientSettingsContainer.js"></script>
