import {
    onMounted,
    reactive,
    computed
} from 'vue';
import { useStore } from 'vuex';
import {
    validateField,
    validateRequired,
    validateWhitespace
} from '@/helpers/validations';
import {
    Modal,
    Title,
    Preloader,
    Input,
    Dropdown,
    Checkbox,
    Button
} from 'account-ui-components';

export default {
    name: 'ModalCreateClient',

    components: {
        Modal,
        Title,
        Preloader,
        Input,
        Dropdown,
        Checkbox,
        Button
    },

    props: {
        onClose: {
            type: Function,
            required: true
        }
    },

    setup(props) {
        const store = useStore();
        const validations = {
            clientId: [
                validateRequired,
                validateWhitespace
            ],
            description: validateRequired
        };
        const loading = reactive({
            modal: true,
            button: false
        });
        const client = reactive({
            clientId: null,
            description: null,
            type: '',
            offlineAccess: false,
            classification: ''
        });
        const errors = reactive({
            clientId: '',
            description: ''
        });
        const clientTypes = computed(() => store.state.clients.clientTypes);
        const types = computed(() => {
            if (clientTypes.value) {
                return (clientTypes.value.map(({ type }) => ({
                    value: type,
                    label: type
                })));
            }

            return [];
        });
        const classifications = [
            {
                label: 'Other',
                value: ''
            },
            {
                label: 'Third Party',
                value: 'thirdParty'
            },
            {
                label: 'Mission Critical',
                value: 'missionCritical'
            },
            {
                label: 'Internal Tool',
                value: 'internalTool'
            }
        ];
        const clientValid = computed(() => (
            Object.entries(client).every(([key, value]) => value || key === 'offlineAccess' || key === 'classification')
            && Object.values(errors).every((value) => !value)
        ));

        onMounted(async () => {
            await store.dispatch('getClientTypes');

            loading.modal = false;
        });

        const onChange = ({ target, target: { name } }) => {
            client[name] = name === 'offlineAccess' ? target.checked : target.value;

            if (errors[name]) {
                errors[name] = '';
            }
        };

        const onBlur = (event) => {
            const {
                name,
                value
            } = event.target;

            if (client[name] !== null) {
                errors[name] = validateField(value, validations[name]);
            }
        };

        const onDropdownChange = ({ name, value }) => {
            client[name] = value;
        };

        const createClient = async () => {
            loading.button = true;

            try {
                await store.dispatch('createClient', client);

                loading.button = false;

                props.onClose();
            } catch (error) {
                if (error.response) {
                    const clientIdError = error.response.data.indexOf('ClientId is already exists') !== -1;

                    if (clientIdError) {
                        errors.clientId = 'This name is already exists';
                    }

                    loading.button = false;
                }
            }
        };

        return {
            loading,
            types,
            classifications,
            client,
            errors,
            clientValid,
            onChange,
            onBlur,
            onDropdownChange,
            createClient
        };
    }
};
