import { createStore } from 'vuex';
import app from './app';
import clients from './clients';

export default createStore({
    modules: {
        app,
        clients
    }
});
