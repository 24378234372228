/* eslint-disable */
export const validateField = (value, validators) => {
    if (!validators) {
        return '';
    }

    if (typeof validators !== 'function') {
        let fieldError = '';

        for (const validator of validators) {
            const error = validator(value);

            if (error) {
                fieldError = error;

                break;
            }
        }

        return fieldError;
    }

    return validators(value);
};

export const validateRequired = (value) => {
    if (!value) {
        return 'This field is required';
    }

    return '';
};

export const validateLength = (value, minLength, maxLength) => {
    if (value) {
        if (minLength && value.length < minLength) {
            return `This field must contain minimum ${minLength} characters`;
        }

        if (maxLength && value.length > maxLength) {
            return `This field must contain maximum ${maxLength} characters`;
        }
    }

    return '';
};

export const validateWhitespace = (value) => {
    if (value) {
        const regex = /\s/g;

        if (regex.test(value)) {
            return 'This field may not include spaces';
        }
    }

    return '';
};
