<template>
    <div class="client-scopes">
        <div class="client-scopes__title-container">
            <Title>
                Scopes
            </Title>

            <Button
                theme="transparent"
                :onClick="onAddClick"
            >
                Add scope
            </Button>
        </div>

        <DataTable
            :headers="headers"
            :items="client.scopes"
        >
            <template v-slot:actions="{ item }">
                <div class="client-scopes__table-actions">
                    <Icon
                        class="client-scopes__remove-icon"
                        name="delete"
                        @click="onRemoveClick(item)"
                    />
                </div>
            </template>
        </DataTable>

        <ModalAddScope
            v-if="modals.addScope"
            :onClose="() => toggleModal('addScope')"
        />

        <ConfirmModal
            v-if="modals.removeScope"
            :text="`Please confirm that you want to remove ${scopeToRemove.scope}`"
            :onYes="onRemoveConfirm"
            :onClose="() => toggleModal('removeScope')"
        />
    </div>
</template>

<script src="./ClientScopes.js"></script>

<style
    src="./ClientScopes.scss"
    lang="scss"
    scoped
></style>
