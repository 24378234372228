import { useStore } from 'vuex';
import {
    Button
} from 'account-ui-components';
import toothIcon from '../../../public/images/tooth.svg';

export default {
    name: 'Forbidden',

    components: {
        Button
    },

    setup() {
        const store = useStore();
        const images = {
            toothIcon
        };

        const onBackClick = () => {
            store.dispatch('signout');
        };

        return {
            images,
            onBackClick
        };
    }
};
