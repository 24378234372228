import {
    ref,
    reactive
} from 'vue';
import {
    Title,
    Button,
    DataTable,
    Icon,
    ConfirmModal
} from 'account-ui-components';
import ModalAddSecret from '@/components/modals/ModalAddSecret/ModalAddSecret.vue';

export default {
    name: 'ClientSecrets',

    props: {
        client: {
            type: Object,
            default: null
        },
        onRemove: {
            type: Function,
            required: true
        }
    },

    components: {
        Title,
        Button,
        DataTable,
        Icon,
        ConfirmModal,
        ModalAddSecret
    },

    setup(props) {
        const headers = [
            {
                value: 'description',
                text: 'Description',
                sortable: true
            },
            {
                value: 'expiration',
                text: 'Expiration',
                sortable: true,
                sortAsDate: true
            },
            {
                value: 'actions',
                text: 'Actions',
                width: '90px'
            },
            {
                value: 'add',
                text: '',
                width: '50px'
            }
        ];
        const secretToRemove = ref(null);
        const modals = reactive({
            addSecret: false,
            removeSecret: false
        });

        const toggleModal = (modal) => {
            modals[modal] = !modals[modal];
        };

        const onAddClick = () => {
            toggleModal('addSecret');
        };

        const onRemoveClick = (redirect) => {
            secretToRemove.value = redirect;

            toggleModal('removeSecret');
        };

        const onRemoveConfirm = () => {
            toggleModal('removeSecret');

            props.onRemove(secretToRemove.value);
        };

        return {
            headers,
            secretToRemove,
            modals,
            toggleModal,
            onAddClick,
            onRemoveClick,
            onRemoveConfirm
        };
    }
};
