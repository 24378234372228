import {
    computed,
    ref
} from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import {
    Modal,
    Input,
    Button
} from 'account-ui-components';
import ModalContent from '../ModalContent/ModalContent.vue';

export default {
    name: 'ModalAddRedirect',

    components: {
        Modal,
        ModalContent,
        Input,
        Button
    },

    props: {
        postLogoutRedirect: {
            type: Boolean,
            default: false
        },
        onClose: {
            type: Function,
            required: true
        }
    },

    setup(props) {
        const store = useStore();
        const route = useRoute();
        const uri = ref('');
        const clientId = computed(() => route.params.id);

        const onChange = ({ target }) => {
            uri.value = target.value;
        };

        const onAddClick = async () => {
            await store.dispatch('addClientRedirect', {
                clientId: clientId.value,
                uri: uri.value
            });

            props.onClose();
        };

        return {
            uri,
            onChange,
            onAddClick
        };
    }
};
