import {
    reactive,
    computed,
    onMounted
} from 'vue';
import { useStore } from 'vuex';
import {
    useRoute,
    useRouter
} from 'vue-router';
import Client from '@/components/Client/Client.vue';

export default {
    name: 'ClientContainer',

    components: {
        Client
    },

    setup() {
        const store = useStore();
        const route = useRoute();
        const router = useRouter();
        const loading = reactive({
            page: true
        });
        const clientId = computed(() => route.params.id);
        const client = computed(() => store.getters.getClientById(clientId.value));

        onMounted(async () => {
            await store.dispatch('getClient', clientId.value);
            await store.dispatch('getClientGrants', clientId.value);

            loading.page = false;
        });

        const onDuplicate = async (newClientId) => {
            loading.page = true;

            await store.dispatch('getClient', newClientId);
            await store.dispatch('getClientGrants', newClientId);

            router.push({ name: 'Client', params: { id: newClientId } });

            loading.page = false;
        };

        const onLogoRemove = () => {
            store.dispatch('removeClientLogo', clientId.value);
        };

        return {
            loading,
            client,
            onDuplicate,
            onLogoRemove
        };
    }
};
