import Alert from '@/api/services/alert';

export default {
    setup() {
        const { alerts, closeAlert } = Alert;

        return {
            alerts,
            closeAlert
        };
    }
};
