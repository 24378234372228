import {
    ref,
    computed,
    onMounted
} from 'vue';
import { useStore } from 'vuex';
import { Logo } from 'account-ui-components';

export default {
    name: 'Header',

    components: {
        Logo
    },

    setup() {
        const store = useStore();
        const navigation = ref(null);
        const env = computed(() => store.state.app.env);
        const user = computed(() => store.state.app.user);
        const username = computed(() => user.value && user.value.name);

        const onSignOutClick = (event) => {
            event.preventDefault();

            store.dispatch('signout');
        };

        onMounted(async () => {
            navigation.value.addEventListener('on-sign-out', onSignOutClick);
        });

        return {
            navigation,
            env,
            username
        };
    }
};
