import {
    reactive,
    computed
} from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import ClientSettings from '@/components/ClientSettings/ClientSettings.vue';

export default {
    name: 'ClientSettingsContainer',

    components: {
        ClientSettings
    },

    setup() {
        const store = useStore();
        const route = useRoute();
        const grants = [
            'authorization_code',
            'client_credentials',
            'hybrid',
            'password',
            'urn:ietf:params:oauth:grant-type:device_code'
        ];
        const loading = reactive({
            button: false
        });
        const clientId = computed(() => route.params.id);
        const client = computed(() => store.getters.getClientById(clientId.value));

        const onSave = async (data) => {
            loading.button = true;

            await store.dispatch('updateClient', {
                id: clientId.value,
                data
            });

            loading.button = false;
        };

        return {
            grants,
            loading,
            client,
            onSave
        };
    }
};
