import {
    ref,
    onMounted
} from 'vue';
import { useStore } from 'vuex';
import { Preloader } from 'account-ui-components';
import Header from '@/components/Header/Header.vue';
import AlertList from '@/components/AlertList/AlertList.vue';

export default {
    name: 'App',

    components: {
        Preloader,
        Header,
        AlertList
    },

    setup() {
        const store = useStore();
        const isLoaded = ref(false);

        onMounted(async () => {
            await store.dispatch('init');
            await store.dispatch('getUserData');
            isLoaded.value = true;
        });

        return {
            isLoaded
        };
    }
};
