<template>
    <ClientList
        :loading="loading"
        :clients="clients"
        :loadMoreClients="loadMoreItems"
        :onClientClick="onItemClick"
    />
</template>

<script src="./ClientListContainer.js"></script>
