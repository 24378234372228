import {
    ref,
    computed,
    onMounted
} from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { Modal, Select, Button } from 'account-ui-components';
import ModalContent from '../ModalContent/ModalContent.vue';

export default {
    name: 'ModalAddScope',

    components: {
        Modal,
        ModalContent,
        Select,
        Button
    },

    props: {
        onClose: {
            type: Function,
            required: true
        }
    },

    setup(props) {
        const store = useStore();
        const route = useRoute();
        const resourceId = ref('');
        const scope = ref('');
        const clientId = computed(() => route.params.id);
        const resources = computed(() => store.state.clients.resources);
        const scopes = computed(() => store.state.clients.scopes[resourceId.value]);

        onMounted(() => {
            store.dispatch('getResources');
        });

        const onResourceIdChange = async ({ target: { value } }) => {
            resourceId.value = value;

            store.dispatch('getScopes', value);
        };

        const onScopeChange = ({ target }) => {
            scope.value = target.value;
        };

        const onAddClick = async () => {
            await store.dispatch('addClientScope', {
                clientId: clientId.value,
                scope: scope.value
            });

            props.onClose();
        };

        return {
            resources,
            resourceId,
            scopes,
            scope,
            onResourceIdChange,
            onScopeChange,
            onAddClick
        };
    }
};
