<template>
    <div class="client-secrets">
        <div class="client-secrets__title-container">
            <Title>
                Secrets
            </Title>

            <Button
                theme="transparent"
                :onClick="onAddClick"
            >
                Add secret
            </Button>
        </div>

        <DataTable
            :headers="headers"
            :items="client.secrets"
        >
            <template v-slot:description="{ item }">
                {{ item.description || '-' }}
            </template>
            <template v-slot:expiration="{ item }">
                {{ item.expiration || 'Never' }}
            </template>
            <template v-slot:actions="{ item }">
                <div class="client-secrets__table-actions">
                    <Icon
                        class="client-secrets__remove-icon"
                        name="delete"
                        @click="onRemoveClick(item)"
                    />
                </div>
            </template>
        </DataTable>

        <ModalAddSecret
            v-if="modals.addSecret"
            :onClose="() => toggleModal('addSecret')"
        />

        <ConfirmModal
            v-if="modals.removeSecret"
            :text="`Please confirm that you want to remove ${secretToRemove.description}`"
            :onYes="onRemoveConfirm"
            :onClose="() => toggleModal('removeSecret')"
        />
    </div>
</template>

<script src="./ClientSecrets.js"></script>

<style
    src="./ClientSecrets.scss"
    lang="scss"
    scoped
></style>
