export default {
    getClients(state, data) {
        state.clients = data;
    },

    getMoreClients(state, payload) {
        const {
            clients,
            page
        } = payload;

        state.clients.page = page;
        state.clients.items = state.clients.items.concat(clients.items);
    },

    getClientTypes(state, data) {
        state.clientTypes = data;
    },

    getClient(state, payload) {
        const {
            id,
            client
        } = payload;

        state.client[id] = client;
    },

    updateClient(state, payload) {
        const {
            id,
            classification
        } = payload;
        state.client[id].classification = classification;
    },

    createClient(state, data) {
        state.clients.items.unshift(data);
    },

    deleteClient(state, id) {
        state.client[id] = null;
    },

    getClientGrants(state, payload) {
        const {
            clientId,
            data
        } = payload;

        state.client[clientId].grants = data;
    },

    getResources(state, data) {
        state.resources = data;
    },

    getScopes(state, payload) {
        const {
            id,
            data
        } = payload;

        state.scopes[id] = data;
    },

    addClientScope(state, payload) {
        const {
            clientId,
            data
        } = payload;

        state.client[clientId].scopes.push(data);
    },

    removeClientScope(state, payload) {
        const {
            clientId,
            scope
        } = payload;

        const index = state.client[clientId].scopes.findIndex((item) => (
            item.scope === scope
        ));

        state.client[clientId].scopes.splice(index, 1);
    },

    addClientRedirect(state, payload) {
        const {
            clientId,
            data
        } = payload;

        state.client[clientId].redirects.push(data);
    },

    removeClientRedirect(state, payload) {
        const {
            clientId,
            redirectId
        } = payload;

        const index = state.client[clientId].redirects.findIndex((item) => (
            item.id === redirectId
        ));

        state.client[clientId].redirects.splice(index, 1);
    },

    addClientSecret(state, payload) {
        const {
            clientId,
            data
        } = payload;

        state.client[clientId].secrets.push(data);
    },

    removeClientSecret(state, payload) {
        const {
            clientId,
            secretId
        } = payload;

        const index = state.client[clientId].secrets.findIndex((item) => (
            item.id === secretId
        ));

        state.client[clientId].secrets.splice(index, 1);
    },

    addClientClaim(state, payload) {
        const {
            clientId,
            data
        } = payload;

        state.client[clientId].claims.push(data);
    },

    removeClientClaim(state, payload) {
        const {
            clientId,
            claimId
        } = payload;

        const index = state.client[clientId].claims.findIndex((item) => (
            item.id === claimId
        ));

        state.client[clientId].claims.splice(index, 1);
    },

    addClientPostLogoutRedirectUri(state, payload) {
        const {
            clientId,
            data
        } = payload;

        state.client[clientId].postLogoutRedirectUris.push(data);
    },

    removeClientPostLogoutRedirectUri(state, payload) {
        const {
            clientId,
            uriId
        } = payload;

        const index = state.client[clientId].postLogoutRedirectUris.findIndex((item) => (
            item.id === uriId
        ));

        state.client[clientId].postLogoutRedirectUris.splice(index, 1);
    },

    addClientCors(state, payload) {
        const {
            clientId,
            data
        } = payload;

        state.client[clientId].cors.push(data);
    },

    removeClientCors(state, payload) {
        const {
            clientId,
            originId
        } = payload;

        const index = state.client[clientId].cors.findIndex((item) => (
            item.id === originId
        ));

        state.client[clientId].cors.splice(index, 1);
    },

    removeClientResource(state, payload) {
        const {
            clientId,
            section,
            resourceId
        } = payload;

        const index = state.client[clientId][section].findIndex((item) => {
            if (section === 'scopes') {
                return item.scope === resourceId;
            }

            return item.id === resourceId;
        });

        state.client[clientId][section].splice(index, 1);
    },

    duplicateClient() {},

    uploadClientLogo() {},

    removeClientLogo() {}
};
