import Api from '@/api';
import Auth from '@/api/services/auth';

export default {
    async init({ commit }) {
        const { hostname } = window.location;

        const isDevelopment = hostname === 'localhost';
        const isTest = hostname.indexOf('test') !== -1;
        const isStaging = hostname.indexOf('staging') !== -1;

        let env = 'production';

        if (isDevelopment) {
            env = 'development';
        } else if (isTest) {
            env = 'test';
        } else if (isStaging) {
            env = 'staging';
        }

        Api.init(env);

        commit('init', env);
    },

    async getUserData({ commit }) {
        const userResponse = await Auth.getUser();
        const data = {
            role: []
        };

        userResponse.data.forEach((item) => {
            if (item.type === 'role') {
                data.role.push(item.value);
            } else {
                data[item.type] = item.value;
            }
        });

        commit('getUserData', data);
    },

    signout({ state }) {
        window.location.href = state.user['bff:logout_url'];
    }
};
