/* eslint-disable */
export const localeTimestampWithShortMonthName = (utcTimestamp) => {
    const dateTime = new Date(utcTimestamp);
    const options = {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour12: false,
        hour: '2-digit',
        minute: '2-digit'
    };

    try {
        return dateTime.toLocaleString([], options);
    } catch (e) {
        return dateTime.toLocaleString();
    }
};
