import { createRouter, createWebHistory } from 'vue-router';
import ClientListContainer from '@/containers/ClientListContainer/ClientListContainer.vue';
import ClientContainer from '@/containers/ClientContainer/ClientContainer.vue';
import Forbidden from '@/components/Forbidden/Forbidden.vue';

const routes = [
    {
        path: '/',
        redirect: '/clients'
    },
    {
        path: '/forbidden',
        name: 'Forbidden',
        component: Forbidden
    },
    {
        path: '/clients',
        name: 'ClientList',
        component: ClientListContainer
    },
    {
        path: '/clients/:id',
        name: 'Client',
        component: ClientContainer
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
