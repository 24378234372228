import {
    ref,
    computed,
    reactive
} from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import dayjs from 'dayjs';
import {
    Modal,
    Title,
    Button,
    Input
} from 'account-ui-components';
import Icon from '@/components/Icon/Icon.vue';
import ModalContent from '../ModalContent/ModalContent.vue';

export default {
    name: 'ModalAddSecret',

    components: {
        Modal,
        ModalContent,
        Title,
        Icon,
        Input,
        Button
    },

    props: {
        onClose: {
            type: Function,
            required: true
        }
    },

    setup() {
        const store = useStore();
        const route = useRoute();
        const date = dayjs(new Date());
        const minExpiration = date.startOf('year').subtract(1, 'day').format('YYYY-MM-DD');
        const maxExpiration = date.add(1, 'year').endOf('year').format('YYYY-MM-DD');
        const newSecret = ref(null);
        const clientId = computed(() => route.params.id);
        const secret = reactive({
            description: `secret-${date.format('YYYYMMDD')}`,
            expiration: null
        });

        const onChange = ({ target: { name, value } }) => {
            secret[name] = value;
        };

        const onAddClick = async () => {
            newSecret.value = await store.dispatch('addClientSecret', {
                clientId: clientId.value,
                secret
            });
        };

        const copyToClipboardClick = () => {
            navigator.clipboard.writeText(newSecret.value);
        };

        return {
            minExpiration,
            maxExpiration,
            newSecret,
            secret,
            onChange,
            onAddClick,
            copyToClipboardClick
        };
    }
};
