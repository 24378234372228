<template>
    <div class="client-post-logout-redirect-uris">
        <div class="client-post-logout-redirect-uris__title-container">
            <Title>
                Post Logout Redirect Uris
            </Title>

            <Button
                theme="transparent"
                :onClick="onAddClick"
            >
                Add PLR Uri
            </Button>
        </div>

        <DataTable
            :headers="headers"
            :items="client.postLogoutRedirectUris"
        >
            <template v-slot:actions="{ item }">
                <div class="client-post-logout-redirect-uris__table-actions">
                    <Icon
                        class="client-post-logout-redirect-uris__remove-icon"
                        name="delete"
                        @click="onRemoveClick(item)"
                    />
                </div>
            </template>
        </DataTable>

        <ModalAddPostLogoutRedirectUri
            v-if="modals.addUri"
            :onClose="() => toggleModal('addUri')"
        />

        <ConfirmModal
            v-if="modals.removeUri"
            :text="`Please confirm that you want to remove ${uriToRemove.uri}`"
            :onYes="onRemoveConfirm"
            :onClose="() => toggleModal('removeUri')"
        />
    </div>
</template>

<script src="./ClientPostLogoutRedirectUris.js"></script>

<style
    src="./ClientPostLogoutRedirectUris.scss"
    lang="scss"
    scoped
></style>
