<template>
    <Modal
        :onClose="onClose"
    >
        <div class="modal-add-secret">
            <div
                v-if="newSecret"
                class="modal-add-secret__success-msg"
            >
                <div class="modal-add-secret__success-msg-title-container">
                    <Title>
                        Success!
                    </Title>
                </div>

                <div class="modal-add-secret__success-msg-text">
                    You have successfully added a new secret.
                </div>

                <div class="modal-add-secret__success-msg-item-list">
                    <div class="modal-add-secret__success-msg-item">
                        <div class="modal-add-secret__success-msg-item-title">
                            Description:
                        </div>
                        <div class="modal-add-secret__success-msg-item-value">
                            {{ secret.description }}
                        </div>
                    </div>

                    <div class="modal-add-secret__success-msg-item">
                        <div class="modal-add-secret__success-msg-item-title">
                            Expiration:
                        </div>
                        <div class="modal-add-secret__success-msg-item-value">
                            {{ secret.expiration || '-' }}
                        </div>
                    </div>

                    <div class="modal-add-secret__success-msg-item">
                        <div class="modal-add-secret__success-msg-item-title">
                            Secret:
                        </div>
                        <div class="modal-add-secret__success-msg-item-value">
                            <div class="modal-add-secret__success-msg-secret">
                                {{ newSecret }}
                            </div>
                            <div
                                class="modal-add-secret__success-msg-copy"
                                title="Copy to clipboard"
                                @click="copyToClipboardClick"
                            >
                                <Icon
                                    class="modal-add-secret__success-msg-copy-icon"
                                    name="content_copy"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-add-secret__success-msg-text">
                    Make sure you copy the secret now.
                    We don't store it and you will not be able to see it again.
                </div>
            </div>

            <ModalContent v-else>
                <h3 class="modal-content__heading">
                    Add Secret
                </h3>

                <div class="modal-content__input-container">
                    <Input
                        name="description"
                        label="Description"
                        :value="secret.description"
                        :required="true"
                        :onChange="onChange"
                    />
                </div>

                <div class="modal-content__input-container">
                    <Input
                        type="date"
                        name="expiration"
                        label="Expiration date"
                        placeholder="Expiration Date"
                        :value="secret.expiration"
                        :min="minExpiration"
                        :max="maxExpiration"
                        :onChange="onChange"
                    />
                </div>

                <div class="modal-content__buttons-container">
                    <div class="modal-content__button-holder">
                        <Button
                            theme="transparent"
                            :fluid="true"
                            :onClick="onClose"
                        >
                            Cancel
                        </Button>
                    </div>
                    <div class="modal-content__button-holder">
                        <Button
                            :disabled="!secret.description"
                            :fluid="true"
                            :onClick="onAddClick"
                        >
                            Add
                        </Button>
                    </div>
                </div>
            </ModalContent>
        </div>
    </Modal>
</template>

<script src="./ModalAddSecret.js"></script>

<style
    src="./ModalAddSecret.scss"
    lang="scss"
    scoped
></style>
