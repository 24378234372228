<template>
    <Modal
        :onClose="onClose"
    >
        <div class="modal-delete-client">
            <div class="modal-delete-client__title-container">
                <Title>
                    Delete client
                </Title>
            </div>

            <div class="modal-delete-client__text">
                Please confirm that you want to delete
                <div class="modal-delete-client__highlighted-text">
                    {{ client.clientId }}
                </div>
                by writing the name of the client below
            </div>

            <div class="modal-delete-client__input-container">
                <Input
                    name="clientIdConfirmation"
                    label="Client name"
                    :value="clientNameConfirmation"
                    required
                    :onChange="onChange"
                />
            </div>

            <div class="modal-delete-client__button-container">
                <Button
                    :disabled="isDeleteButtonDisabled"
                    theme="transparent"
                    :loading="loading.button"
                    :onClick="deleteClient"
                >
                    Delete
                </Button>
            </div>
        </div>
    </Modal>
</template>

<script src="./ModalDeleteClient.js"></script>

<style
    src="./ModalDeleteClient.scss"
    lang="scss"
    scoped
></style>
