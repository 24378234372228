<template>
    <div class="forbidden">
        <img
            class="forbidden__image"
            :src="images.toothIcon"
            alt=""
        />

        <div class="forbidden__title">
            You don't have enough rights to access this resource
        </div>

        <div class="forbidden__text">
            Try to use a different account or get admin rights
        </div>

        <Button
            :onClick="onBackClick"
        >
            Go back
        </Button>
    </div>
</template>

<script src="./Forbidden.js"></script>

<style
    src="./Forbidden.scss"
    lang="scss"
    scoped
></style>
