import {
    reactive,
    computed,
    onMounted
} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import ClientList from '@/components/ClientList/ClientList.vue';

export default {
    components: {
        ClientList
    },

    setup() {
        const store = useStore();
        const router = useRouter();
        const loading = reactive({
            page: true
        });
        const clients = computed(() => store.state.clients.clients);

        onMounted(async () => {
            await store.dispatch('getClients');

            loading.page = false;
        });

        const loadMoreItems = async ({ loaded }) => {
            const nextPage = clients.value.page + 1;

            const newClients = await store.dispatch('getClients', nextPage);

            loaded(newClients.items.length, 10);
        };

        const onItemClick = (e, { id }) => {
            router.push({ name: 'Client', params: { id } });
        };

        return {
            loading,
            clients,
            loadMoreItems,
            onItemClick
        };
    }
};
