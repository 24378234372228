const Icon = {
    name: 'Icon',

    props: {
        name: {
            type: String,
            required: true
        }
    }
};

export default Icon;
