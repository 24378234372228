import {
    ref,
    reactive,
    computed
} from 'vue';
import { useStore } from 'vuex';
import {
    useRoute,
    useRouter
} from 'vue-router';
import {
    Modal,
    Title,
    Input,
    Button
} from 'account-ui-components';

export default {
    name: 'ModalDeleteClient',

    components: {
        Modal,
        Title,
        Input,
        Button
    },

    props: {
        client: {
            type: Object,
            required: true
        },
        onClose: {
            type: Function,
            required: true
        }
    },

    setup(props) {
        const store = useStore();
        const route = useRoute();
        const router = useRouter();
        const clientNameConfirmation = ref(null);
        const loading = reactive({
            button: false
        });
        const clientId = computed(() => route.params.id);
        const isDeleteButtonDisabled = computed(() => (
            clientNameConfirmation.value !== props.client.clientId
        ));

        const onChange = ({ target }) => {
            clientNameConfirmation.value = target.value;
        };

        const deleteClient = async () => {
            loading.button = true;

            store.dispatch('deleteClient', clientId.value);

            router.push('/clients');
        };

        return {
            loading,
            clientNameConfirmation,
            isDeleteButtonDisabled,
            onChange,
            deleteClient
        };
    }
};
