<template>
    <Modal
        :onClose="onClose"
    >
        <div class="modal-duplicate-client">
            <div class="modal-duplicate-client__title-container">
                <Title>
                    Duplicate client
                </Title>
            </div>
            <div class="modal-duplicate-client__input-container">
                <Input
                    label="New client name"
                    :value="newClientName"
                    :error="errors.name"
                    required
                    :onChange="onInputChange"
                />
            </div>

            <div class="modal-duplicate-client__button-container">
                <Button
                    :disabled="!newClientName"
                    :loading="loading.button"
                    :onClick="duplicateClient"
                >
                    Duplicate
                </Button>
            </div>
        </div>
    </Modal>
</template>

<script src="./ModalDuplicateClient.js"></script>

<style
    src="./ModalDuplicateClient.scss"
    lang="scss"
    scoped
></style>
