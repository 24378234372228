import { ref } from 'vue';
import router from '@/router';

export default {
    alerts: ref([]),

    init() {
        router.beforeEach(() => {
            this.alerts.value = this.alerts.value.filter((alert) => alert.keepAfterRouteChange);
        });
    },

    alert(alert) {
        this.alerts.value.push(alert);
    },

    closeAlert(i) {
        this.alerts.splice(i, 1);
    },

    success(message, keepAfterRouteChange = false) {
        this.alert({ message, type: 'success', keepAfterRouteChange });
    },

    error(message, keepAfterRouteChange = false) {
        this.alert({ message, type: 'error', keepAfterRouteChange });
    },

    info(message, keepAfterRouteChange = false) {
        this.alert({ message, type: 'info', keepAfterRouteChange });
    },

    warn(message, keepAfterRouteChange = false) {
        this.alert({ message, type: 'warning', keepAfterRouteChange });
    }
};
