<template>
    <Client
        :loading="loading"
        :client="client"
        :onDuplicate="onDuplicate"
        :onLogoRemove="onLogoRemove"
    />
</template>

<script
    src="./ClientContainer.js"
></script>
