<template>
  <div
    class="alert-list"
    v-if="alerts.length">
    <div
      v-for="({ message, type }, i) in alerts"
      class="alert-list__item"
      :key="i"
    >
      <i :class="`icon-${type}`"/>
      <span>{{ message }}</span>
      <i
        class="icon-close"
        @click="closeAlert(i)"
      />
    </div>
  </div>
</template>

<script src="./AlertList.js"></script>

<style
    src="./AlertList.scss"
    lang="scss"
    scoped
></style>
