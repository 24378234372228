import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import {
    Modal,
    Button
} from 'account-ui-components';
import ModalContent from '../ModalContent/ModalContent.vue';

export default {
    name: 'ModalDeleteClientResource',

    components: {
        Modal,
        ModalContent,
        Button
    },

    props: {
        clientResource: {
            type: Object,
            required: true
        },
        onClose: {
            type: Function,
            required: true
        }
    },

    setup(props) {
        const store = useStore();
        const route = useRoute();
        const clientId = computed(() => route.params.id);
        const clientResourceIdentifierToDisplay = computed(() => {
            const { section, item } = props.clientResource;

            if (section === 'redirects' || section === 'postLogoutRedirectUris') {
                return item.uri;
            } if (section === 'scopes') {
                return item.scope;
            } if (section === 'secrets') {
                return item.description;
            }
            return `${item.type} - ${item.value}`;
        });

        const removeClientResource = () => {
            const { section, item: { id, scope } } = props.clientResource;

            store.dispatch(
                'removeClientResource',
                {
                    clientId: clientId.value,
                    section,
                    resourceId: scope ?? id
                }
            );

            props.onClose();
        };

        return {
            clientResourceIdentifierToDisplay,
            removeClientResource
        };
    }
};
